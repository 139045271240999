@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.order-success {
  display: flex;
  flex-direction: column;
  max-width: 375px;
  margin: 32px auto 0;
  animation-name: entryAnimation;
  animation-duration: 0.32s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;

  @keyframes entryAnimation {
    0% {
      top: 12px; opacity: 0;
    }
    100% {
      top: 0; opacity: 1;
    }
  }

  h1 {
    text-align: center;
    font-size: 32px;
  }
  .success-container {
    margin-top: 8px;
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: $z6;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      background: url(../../../assets/pattern.svg) center center no-repeat;
      width: 640px;
      height: 241px;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
    .order-summary {
      .summary-row {
        display: flex;
        justify-content: space-between;
        padding: 4px 0;

        .row-title {
        }
        .row-value {
          font-weight: 600;
        }
      }
    }
    .summary-actions {
      margin-top: 24px;
      display: flex;
      .button {
        margin-bottom: 0;
      }
    }
  }
  .actions-footer {
    margin-top: 32px;
    align-self: flex-end;
    .backToOrders {
      border: 1px solid transparent;
      background: $brand;
      color: $white;
      cursor: pointer;
      display: inline-block;
      padding: 12px 24px;
      border-radius: 5px;
      font-weight: 600;
      &:hover {
        // border-color: $brand;
        background: darken($brand, 4%);
        // color: $white;
      }
    }
  }
}
