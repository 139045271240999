@import "./colors";

input[type="tel"],
input[type="email"],
input[type="password"],
input[type="text"] {
  -webkit-appearance: none;
  background: $background;
  border: none;
  padding: 10px 8px;
  border-radius: 5px;
  border: 1px solid transparent;
  line-height: 1;
  transition: 0.17s all ease-out;
  display: block;
  width: 100%;
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
    background: $white;
    border-color: $brand;
  }
  &::placeholder {
    color: $lighter;
  }
}

.input-search {
  position: relative;
  input[type="text"] {
    padding-right: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    right: 16px;
    top: 13px;
    width: 16px;
    height: 16px;
    background: url(../assets/icons/icon-search.svg) center center no-repeat;
    z-index: 0;
    pointer-events: none;
  }
  .clear {
    // width: 16px;
    // height: 16px;
    cursor: pointer;
    background-color: $placeholder;
    border-radius: 32px;
    padding: 2px;
    position: absolute;
    z-index: 2;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      // width: 20px;
      // height: 20px;
      padding: 2px;
      svg path {
        fill: $dark;
      }
    }
    &:hover {
      // background-color: $borders;
      .icon svg path {
        // fill: $dark;
      }
    }
  }
}
