@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

#orders {
  .upcoming-orders-wrapper {
    .upcoming-orders {
      margin-top: -16px;
      .upcoming-order {
        flex: 0 1 33.333%;
        @media only screen and (max-width: 1300px) {
          flex: 0 1 33.333%;
        }
        @media only screen and (max-width: 800px) {
          flex: 0 1 50%;
        }
        @media only screen and (max-width: 600px) {
          flex: 0 1 100%;
        }
      }
    }
    margin-bottom: 40px;
    @media only screen and (max-width: 991px) {
      margin-top: 48px;
    }
  }

  &.no-orders {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-bottom: 120px;

    @media only screen and (max-width: 991px) {
      margin-top: 40px;
    }

    .no-orders-inner {
      text-align: center;
      h2 {
        font-weight: 600;
      }
      p {
        color: $light;
        font-size: 18px;
      }
      .button {
        padding-right: 32px;
        padding-left: 32px;
      }
      .new-order-cta {
        margin-top: 64px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          background: url(../../assets/pattern2.svg) center center no-repeat;
          width: 285px;
          height: 241px;
          top: 120%;
          left: 50%;
          transform: translate(-50%, -50%);
          animation: 1s 0.1s backgroundSizeB forwards cubic-bezier(0.215, 0.61, 0.355, 1);
          background-size: 70%;
          opacity: 0;

          @keyframes backgroundSizeB {
            to {
              background-size: 100%;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.table-container {
  background: #fff;
  padding: 16px;
  border-radius: 10px;
  box-shadow: $z6;

  select {
    margin-bottom: 24px;
  }

  .table-actions {
    select {
      margin-left: 12px;
    }
  }

  table {
    color: $light;
    tr {
      .orderId {
        a {
          color: $brand;
          font-size: 16px;
          font-weight: 600;
          // max-width: 100px;
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .viewOrderWrapper {
      text-align: left;

      .viewOrder {
        visibility: hidden;
        cursor: pointer;

        &:hover {
          color: $brand;
        }
      }
    }

    tbody tr:hover {
      .viewOrderWrapper .viewOrder {
        visibility: visible;
      }
    }
  }
}

.newOrder {
  background: $brand;
  border: 1px solid transparent;
  display: inline-block;
  padding: 10px 24px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 0.16s all ease-out;

  &:hover {
    background-color: darken($brand, 4%);
  }

  &:active {
    background-color: darken($brand, 8%);
  }
}

.widgets {
  margin: 0 -16px 32px;
  display: flex;
}

.widget {
  flex: 0 1 33%;
  margin: 0 16px;
  padding: 40px;
  height: 160px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  box-shadow: $z6;
  background: $white;

  .highlight {
    font-size: 24px;
    color: $brand;
  }

  .description {
    font-size: 16px;
    margin-top: 6px;
    color: $light;
  }

  &.widget-user {
    background: $brandDark;
    box-shadow: $z6, inset 16px -16px 80px rgba(0, 0, 0, 0.35);
    position: relative;
    z-index: 1;

    .widget-user-content {
      display: flex;
      align-items: center;

      .company-image {
        width: 56px;
        height: 56px;
        border-radius: 10px;
        background: $white;
        box-shadow: $z1;
        background-position: center center;
        background-size: 40px 40px;
        background-repeat: no-repeat;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .user-info {
        margin-right: 16px;
        margin-bottom: 4px;

        .company-name {
          margin: 2px 0;
          color: $lighter;
          font-size: 16px;
        }
        .user-name {
          margin: 2px 0;
          font-size: 18px;
          font-weight: 600;
          color: $white;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      background: url(../../assets/widget-user-pattern.svg) center center no-repeat;
      width: 136px;
      height: 115px;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
      // opacity: 0.5;
    }

    &:before {
      content: "";
      position: absolute;
      background: url(../../assets/widget-user-pattern2.svg) center center no-repeat;
      width: 136px;
      height: 115px;
      right: 0;
      top: 0;
      z-index: -1;
      opacity: 0.5;
    }
  }
}
