@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.newOrderTemplate {
  background: $white;
  padding: 16px 0;
  box-shadow: $z6;
  border-radius: 10px;
  //   overflow-x: hidden;
  @media only screen and (max-width: 767px) {
    display: none;
  }

  .newOrderHead {
    padding: 24px 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin: 0;
    }
    .timestamp {
      // color: $light;
      font-size: 14px;
      margin-top: 4px;
    }
  }
  .products-list {
    .table {
      thead {
        tr {
          th {
            padding-right: 32px;
            padding-left: 32px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding-right: 32px;
            padding-left: 32px;
            &.productId {
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &.amount {
              display: flex;
              span {
                margin: 0 4px;
              }
              .product-increase,
              .product-decrease {
                width: 24px;
                height: 24px;
                cursor: pointer;
              }
              .product-decrease {
                background: url(../../assets/icons/icon-minus-circle.svg) center center no-repeat;
              }
              .product-increase {
                background: url(../../assets/icons/icon-plus-circle.svg) center center no-repeat;
              }
            }
          }
          &.summary {
            border: none;
            td {
              padding-top: 8px;
              padding-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .order-summary {
    padding: 16px 32px;
    text-align: left;
  }
}
.mobile-order-summary-cta {
  background: $brandDark;
  color: $placeholder;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  right: 0;
  width: 100%;
  padding: 22px 16px;
  font-size: 18px;
  text-align: center;
  display: none;
  
  @media only screen and (max-width: 767px) {
    display: block;
  }
  
  .mobile-order-summary-cta-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      color: $brandLight;
      strong {
        color: $white;
      }
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
      margin-left: 8px;
      path {
        fill: $brandLight;
      }
    }
  }
}
