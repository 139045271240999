@import "../../styles/colors";
.loader {
  margin: 16px auto;
  display: flex;
  justify-content: center;
  &.light {
    margin: 16px;
    .spinner .path {
      stroke: $placeholder;
      animation: dash 1.5s ease-in-out infinite;  
    }
  }
  .spinner {
    // animation: rotate 2s linear infinite;
    z-index: 2;
    width: 32px;
    height: 32px;

    & .path {
      stroke: $brand;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite, colors 6s infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @keyframes colors {
    0% {
      stroke: $light;
    }
    50% {
      stroke: $light;
    }
    100% {
      stroke: $light;
    }
  }
}
