@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";
.loaderB {
  position: fixed;
  background: rgba($dark, 0.35);

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 91;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.dark {
    background: rgba($dark, 0.35);
    .loaderB-inner {
      background-color: $darker;
      .loader-icon {
        // background: $dark;
        &:before {
          background: $light;
        }
        svg path {
          fill: $darker;
        }
      }
      h3 {
        color: $placeholder;
      }
      h4 {
        color: $brand;
      }
    }
  }
  .loaderB-inner {
    background: $white;
    box-shadow: $z6;
    border-radius: 12px;
    padding: 72px 80px;
    margin-top: -40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 100vh;
      border-radius: 0;
      box-shadow: none;
      padding: 16px;
    }

    h3 {
      margin: 0 0 12px;
      color: $brand;
      font-weight: 600;
    }
    h4 {
      margin: 0;
      color: $dark;
    }

    .loader-icon {
      margin: 0 0 24px;
      // background: $brandLight;
      // width: 102px;
      // height: 98px;
      position: relative;


      @media only screen and (max-width: 767px) {
        width: 102px;
        height: 98px;
        svg {
          width: 102px;
          height: 98px;
        }
      }

      svg {
        position: relative;
        z-index: 2;
      }

      svg path {
        fill: $white;
      }

      &:before {
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        background: $brand;
        animation-name: loading;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-iteration-count: infinite;
        animation-delay: 0.4s;
      }

      @keyframes loading {
        0% {
          width: 0%;
        }
        10% {
          width: 10%;
        }
        50% {
          width: 70%;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}
