@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

#supplier {
  // .button {
  //   margin: 0;
  // }

  .table-container {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    box-shadow: $z6;

    .table-actions {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    table {
      color: $light;

      .orderId {
        a {
          color: $brand;
          max-width: 100px;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .client {
        font-weight: 600;
      }

      .viewOrderWrapper {
        text-align: left;

        .viewOrder {
          visibility: hidden;
          cursor: pointer;

          &:hover {
            color: $brand;
          }
        }
      }

      tbody tr:hover {
        .viewOrderWrapper .viewOrder {
          visibility: visible;
        }
      }
    }
  }
}

.newOrder {
  background: $brand;
  border: 1px solid transparent;
  display: inline-block;
  padding: 10px 24px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 0.16s all ease-out;

  &:hover {
    background-color: darken($brand, 4%);
  }

  &:active {
    background-color: darken($brand, 8%);
  }
}

.widgets {
  margin: 0 -16px 32px;
  display: flex;
}
.page-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filterOrders {
  // background: $white;
  // box-shadow: $z6;
  // border-radius: 5px;
  display: flex;
  justify-content: space-around;
}

.upcoming-orders {
  display: flex;
  flex-wrap: wrap;
  margin: 24px -16px;
  .upcoming-order {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 16px;
    flex: 0 1 20%;

    @media only screen and (max-width: 1800px) {
      flex: 0 1 25%;
    }
    @media only screen and (max-width: 1300px) {
      flex: 0 1 33.333%;
    }
    @media only screen and (max-width: 800px) {
      flex: 0 1 50%;
    }
    @media only screen and (max-width: 600px) {
      flex: 0 1 100%;
    }

    .upcoming-order-inner {
      background: $white;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      box-shadow: $z3;
      padding: 12px;
      width: 100%;
      top: 0;
      position: relative;
      font-size: 16px;
      transition: 0.24s all;
      color: $dark;
      line-height: 1.6;

      &:focus {
        outline: none;
        .upcoming-order-border {
          border-color: $brand;
        }
      }
      &:hover {
        top: -2px;
        z-index: 2;
        box-shadow: $z5;
        &:after {
          animation-name: zigzagAnimation;
          animation-duration: 0.8s;
          animation-delay: 0.6s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;

          @keyframes zigzagAnimation {
            0% {
              background-position-x: 0;
            }
            100% {
              background-position-x: -11px;
            }
          }
        }
      }
      &:after {
        content: "";
        transition: 2s all;
        position: absolute;
        bottom: -10px;
        left: 0;
        right: 0;
        background: url(../../assets/triangle.svg) 0 0 repeat-x;
        z-index: 2;
        height: 10px;
        width: 100%;
      }

      .upcoming-order-border {
        padding: 8px 24px 16px;
        border: 4px double $placeholder;
        border-radius: 12px;
        div {
          margin: 8px 0;
        }
        .company {
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 56px;
          }
        }

        .title {
          color: $brand;
          font-size: 16px;
          font-weight: 600;
          padding: 8px 0;
          margin: 12px 0;
          border-top: 1px solid $placeholder;
          border-bottom: 1px solid $placeholder;
        }

        .timestamp {
          font-size: 12px;
          color: $light;
        }
      }
    }
  }
}
