.status {
  color: $light;
  margin-top: 4px;
  font-size: 12px;
  border-radius: 5px;
  color: $white;
  padding: 2px 6px;
  display: inline-block;
  font-weight: 600;

  &.status-pending {
    background: lighten($yellow, 22%);
    color: darken($yellow, 30%);
  }

  &.status-approved {
    background: lighten($brand, 50%);
    color: $brand;
  }
}
