@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.button {
  border: 1px solid transparent;
  background: $brandDark;
  color: $white;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  padding: 12px 24px;
  font-weight: 600;
  // margin-top: 16px;
  // margin: 0 0 12px 0;
  letter-spacing: 0.2px;
  transition: 0.16s all;
  user-select: none;
  box-shadow: 0 0 0 12px rgba($brand, 0);

  @media only screen and (max-width: 600px) {
    padding: 10px 16px;
    
  }

  .icon{
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
  }

  &.button-full-width {
    display: block;
    text-align: center;
    width: 100%;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba($brand, 0.16);
  }

  &.button-link {
    background: transparent;
    border-color: transparent;
    color: $brandLight;
    &:hover {
      background: inherit;
    }
    &:focus {
      // outline: none;
      // box-shadow:  0 0 0 3px rgba($placeholder, 0.32);
    }
    &:disabled {
      color: rgba($brandLight, 0.4);
      background: inherit;
    }
  }
  &.button-yellow {
    color: $darker;
    background: $yellow;
    box-shadow: 0 0 0 8px rgba($yellow, 0);
    &:hover {
      background-color: desaturate(darken($yellow, 14%), 28%);
    }
    &:focus {
      box-shadow: 0 0 0 3px rgba($yellow, 0.24);
    }
    &:disabled {
      background-color: rgba($darker, 0.35);
    }
  }

  &.button-ghost {
    background: transparent;
    border-color: $white;
    color: $white;
    &:hover {
      background: inherit;
    }
    &:focus {
      // outline: none;
      // box-shadow:  0 0 0 3px rgba($placeholder, 0.32);
    }
    &.button-ghost-green {
      border-color: $brand;
      color: $brand;
      &:hover {
        background-color: $brand;
        color: $white;
      }
    }
  }

  &.button-ghost-dark {
    background: $white;
    border: 1px solid $placeholder;
    cursor: pointer;
    color: $brand;
    display: inline-block;
    border-radius: 5px;
    font-weight: 600;
    margin-left: 16px;
    &:hover {
      border-color: $brand;
      background: inherit;
      color: inherit;
    }
    &:disabled {
      border-color: $placeholder;
      background: inherit;
      color: inherit;
    }
  }

  &.button-light {
    background: $placeholder;
    color: $dark;
    box-shadow: none;
    &:hover {
      background: $borders;
    }
  }

  &:hover {
    // border-color: $brand;
    background: darken($brand, 4%);
    // color: $white;
  }
  &.loading,
  &:disabled {
    background: $placeholder;
    cursor: not-allowed;
  }

  .loader {
    margin: 0;
    svg {
      width: 46px;
      height: 16px;
      transform: scale(1.5);
    }
  }
}
