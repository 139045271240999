@import "../../styles/colors";
@import "../../styles/shadows";

.modal {
  background: rgba($background, 0.95);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  // overflow-y: auto;

  &.show {
    display: block;
  }
  .modal-container {
    display: flex;
    height: 100%;
    justify-content: center;

    .modal-content {
      max-width: 520px;
      background: $white;
      margin: 24px;
      width: 100%;
      box-shadow: $z3;
      border-radius: 12px;
      height: max-content;
      padding: 32px;

      .modal-header {
        display: flex;
        justify-content: space-between;
        .icon {
          border: 1px solid $lighter;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 32px;
          cursor: pointer;
          svg path {
            fill: $light;
          }

          &:hover {
            svg path {
              fill: $dark;
            }
            border-color: $dark;
          }
        }
      }
      .modal-body {
        // padding: 40px;
        max-height: calc(100vh - 146px);
        // overflow-y: scroll;
        iframe {
          width: 100%;
          max-height: calc(100vh - 210px);
        }
      }
      .modal-footer {
        padding: 40px;
      }
    }
  }
}

.pdf-document {
  .button {
    margin: 0 32px 32px;
  }
  span{
    margin: 0 32px 32px;
  }
}
