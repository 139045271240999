@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.welcome {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  @media only screen and (max-width: 800px) {
    align-items: flex-start;
    min-height: auto;
  }

  .welcome-inner {
    width: 100%;
    max-width: 740px;
    margin: 0 0 64px;

    @media only screen and (max-width: 1300px) {
      // max-width: none;
      margin-bottom: 0;
      margin-top: 24px;
    }
    h1 {
      // border-bottom: 1px solid $placeholder;
      padding-top: 8px;
      padding-left: 140px;
      // padding-bottom: 24px;
      margin-bottom: 24px;
      color: $dark;
      font-size: 32px;
      font-weight: 600;

      @media only screen and (max-width: 1300px) {
        padding-left: 60px;
      }
      @media only screen and (max-width: 800px) {
        padding-left: 40px;
      }
    }
    p {
      font-size: 18px;
      color: $light;
      line-height: 1.64;
    }
    h4 {
      font-weight: 600;
      margin: 32px 0 16px;
      @media only screen and (max-width: 800px) {
        margin-top: 16px;
      }
    }

    .check-list {
      margin-bottom: 32px;
    }

    .welcome-message {
      margin: 0 32px 40px;
      padding-bottom: 12px;
      border-bottom: 1px solid $placeholder;

      @media only screen and (max-width: 800px) {
        margin: 0 0 12px;
      }
    }

    .welcome-animate {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      animation: welcomeAnimate 0.24s 1s ease-in-out 1 forwards;
      @keyframes welcomeAnimate {
        to {
          margin-top: 0;
          opacity: 1;
        }
      }

      @media only screen and (max-width: 1800px) {
      }
      @media only screen and (max-width: 1300px) {
      }
      @media only screen and (max-width: 800px) {
        flex-direction: column;
        align-items: stretch;
      }
      @media only screen and (max-width: 600px) {
      }

      .now-you-can {
        margin: 0 32px;

        @media only screen and (max-width: 800px) {
          margin: 0 0 8px;
        }
      }

      .your-location {
        margin: 0 32px;

        @media only screen and (max-width: 800px) {
          margin: 0 0 12px;
        }

        .location-card {
          // margin: 0 -16px;
          padding: 0;
          min-width: 300px;
          .location-card-inner .location-content .location-row.location-go {
            display: none;
          }
          @media only screen and (max-width: 800px) {
            width: 100%;
            min-width: auto;
          }
        }
      }
    }
  }
}
