#newOrder {
  .mobile-tabs {
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    ul {
      user-select: none;
      display: flex;
      justify-content: space-evenly;

      li {
        cursor: pointer;
        width: 50%;
        text-align: center;
        padding: 16px;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid $borders;
        color: $light;

        span {
          min-width: 20px;
          height: 20px;
          display: inline-block;
          line-height: 20px;
          font-size: 13px;
          margin-right: 2px;
          padding: 1px 6px;
          background: $yellow;
          color: $darker;
          border-radius: 40px;
          text-align: center;
        }

        &.shown {
          color: $brand;
          border-bottom-color: $brand;
          border-bottom-width: 2px;
        }
      }
    }

    .mobile-tab-content {
      display: none;

      .mobile-tab-content-inner {
        padding: 0 0 32px;

        h1 {
          margin-bottom: 24px;
        }

        .empty-list {
          margin-top: 40px;

          h1 {
            margin-bottom: 24px;
          }

          h4 {
            margin-bottom: 32px;
          }
        }
      }

      &.shown {
        display: block;
      }

      .cta-search {
        text-align: center;

        .icon svg path {
          fill: $white;
        }
      }

      .products-search {
        // margin: 24px 0;

        input {
          background: $white;
          border: 1px solid $borders;
          box-shadow: $z2;
          line-height: 1.5;
          &:focus {
            border-color: $brand;
          }
        }
      }

      h6 {
        color: $light;
        margin: 24px 0 8px;
        font-size: 13px;
        font-weight: 600;
      }

      .products-list-container {
        .amountText {
          width: 50px;
          background: transparent;
          direction: rtl;
          text-align: right;
          padding: 6px;
          border: 1px solid $borders;
        }

        .product {
          outline: none;
          box-shadow: none;
          border-radius: 0;
          // padding: 12px 0;
          // background: $white;
          // box-shadow: $z3;
          // margin: 8px 0;
          // padding: 12px 16px;
          margin-left: -16px;
          margin-right: -16px;
          padding-right: 16px;
          padding-left: 16px;

          &:active {
            background: $placeholder;
          }

          .productActions {
            .product-increase {
              .icon svg path {
                // fill: $dark;
              }
            }
            .product-reset {
              display: flex;
              align-items: center;
              justify-content: center;

              .icon svg path {
                fill: $light;
              }
            }
          }

          &:active {
            .product-increase {
              transform: scale(1);
              box-shadow: none;
            }
          }

          &:focus {
            // outline: none;
            // box-shadow: none;
            // border-radius: 5px;
            padding: 12px 16px;

            .product-increase {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

.newOrderHeader {
  margin: 24px 0;
  display: none;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: $dark;
  
  @media only screen and (max-width: 767px) {
    display: flex;
  }
  
  .icon{
    margin-left: 4px;
  }

  // .BackToOrders {
  //   display: inline-block;
  //   width: 40px;
  //   height: 40px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
}
