@import "src/styles/colors.scss";

.icon {
  // width: 24px;
  // height: 24px;
  line-height: 0;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
