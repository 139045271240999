@import "src/styles/colors.scss";

.printVersion {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // display: inline-block;
  cursor: pointer;
  color: $brand;
  svg {
    margin: 0 4px;
    path {
      fill: $brand;
    }
  }
}
.orderPrint {
  max-width: 900px;
  margin: 0 auto;

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  thead {
    font-weight: 600;
  }
  tr.noborder td {
    border-color: transparent;
  }
  td {
    border-bottom: 1px solid $borders;
  }

  .invoice-box {
    margin: auto;
    padding: 30px;
    font-size: 14px;
    line-height: 24px;
    font-family: "Heebo", "Helvetica Neue", "Helvetica";
    color: #555;
  }

  .margin-top {
    margin-top: 50px;
  }

  .justify-center {
    text-align: center;
  }

  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: right;
  }

  .invoice-box table td {
    padding: 5px;
    vertical-align: top;
  }

  .invoice-box table tr td:nth-child(2) {
    text-align: right;
  }

  .invoice-box table tr.top table td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
  }

  .invoice-box table tr.information table td {
    padding-bottom: 40px;
  }

  .invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #efefef;
    font-weight: bold;
  }

  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }

  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
  }

  .invoice-box table tr.item.last td {
    border-bottom: none;
  }

  .invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
      width: 100%;
      display: block;
      text-align: center;
    }

    .invoice-box table tr.information table td {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}
