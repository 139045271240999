$darker: #1d2832;
$dark: #374f64;
$light: #91a9bf;
$lighter: #beccd8;
$borders: #dee3e9;
$placeholder: #ebf1f5;
$background: #f7fafc;
$lightest: #fafbfc;
$yellow: #ffd259;
$brand: #2d8b70;
$brandDark: #00402D;
// $brand: #286144;
// $brandDark: darken($brand,4%);
$brandLight: #d8e8e4;
$red: #d33c4e;
// $brand: #3d7e9a;
$white: #fff;
