@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.locations-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: 48px;

  .location-card-container {
    width: 25%;
    @media only screen and (max-width: 1300px) {
      width: 33.333%;
    }
    @media only screen and (max-width: 800px) {
      width: 50%;
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
