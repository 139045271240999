@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.printable {
  padding: 0 40px;
  font-size: 10px !important;
  * {
    color: #000;
  }
  tr th {
    text-align: right;
  }
  .company-image{
    img{
      height: 72px;
      margin-left: 24px;
    }
  }
  tr.header {
    border-top: none;
    th {
      padding: 20px 0;
      // text-align: center;
      font-size: 24px;
      color: $dark;
      &.order-details {
        font-size: 12px;
        .order-details-inner {
          display: flex;
          align-items: center;
          .company-image {
            margin: 0 4px;
          }
          .company-details {
          }
        }
      }
    }
  }
}

#orders {
  .table-container {
    background: #fff;
    padding: 16px;
    border-radius: 10px;
    box-shadow: $z3;
    .table-actions {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    table {
      color: $light;

      .orderId {
      }
      .client {
        font-weight: 600;
      }
      .viewOrderWrapper {
        text-align: left;

        .viewOrder {
          visibility: hidden;
          cursor: pointer;
          &:hover {
            color: $brand;
          }
        }
      }
      tbody tr:hover {
        .viewOrderWrapper .viewOrder {
          visibility: visible;
        }
      }
    }
  }
}

.newOrder {
  background: $brand;
  border: 1px solid transparent;
  display: inline-block;
  padding: 10px 24px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  transition: 0.16s all ease-out;
  &:hover {
    background-color: darken($brand, 4%);
  }
  &:active {
    background-color: darken($brand, 8%);
  }
}

#viewOrder {
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  box-shadow: $z3;
  transition: 0.12s opacity;
  overflow-x: auto;
  
  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1300px) {
  }
  @media only screen and (max-width: 991px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 600px) {
  }

  &.shipping * {
    color: $lighter;
  }
  .products-list {
    min-width: 500px;
  }
  .viewOrderHead {
    text-align: center;
    h2 {
      color: $brand;
      font-size: 28px;
      margin: 8px 0 32px 0;
    }
  }
  .table thead tr {
    border-top: none;
  }
  .table tbody tr {
    td {
      padding: 12px 8px;
    }
  }
  .table tfoot tr {
    border: none;
    td {
      padding: 4px 8px;
      color: $light;
    }
    &.tfoot-total td {
      font-weight: 600;
      color: $dark;
    }
  }
  .noborder {
    border: none;
  }
}

.order-extra-details {
  background: #fff;
  border-radius: 12px;
  padding: 40px 40px 32px;
  box-shadow: $z6;
  margin-bottom: 40px;
  position: relative;
  z-index: 0;

  // transform: perspective(1000px) rotateY(0deg);
  // transition: 0.24s all cubic-bezier(0.215, 0.61, 0.355, 1);

  table {
    display: block;
    // height: 100px;
  }

  // position: fixed;
  // height: 100%;
  // bottom: 0;
  // top: 0;
  // right: 0;
  // width: 370px;
  // border-radius: 0;
  // z-index: 999;

  .supplierActions {
    margin-top: 32px;
    .button {
      margin-top: 12px;
    }
  }

  h5 {
    color: $brand;
    font-weight: 600;
    margin: 4px 0 12px;
  }

  ul.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
      padding: 4px;
      &.pagination {
        // color: $light;
        font-size: 12px;
      }
      svg path {
        fill: $brand;
      }
    }
  }
  hr {
    border: none;
    display: block;
    margin: 24px -16px;
    height: 1px;
    border-top: 1px solid $placeholder;
  }
  table {
    td {
      width: 50%;
      padding: 2px 0;
    }
  }
}

.review-order {
  position: absolute;
  top: 0;
  right: 0;
  // height: 100%;
  // bottom: 0;
  left: 0;

  background: $brandDark;
  color: $white;
  border-radius: 12px;
  padding: 40px 40px 32px;
  box-shadow: $z6;
  margin-bottom: 40px;
  transform: rotateY(-180deg);
  padding-bottom: 34px;
  h2 {
    margin-top: 0;
  }

  .supplierActions {
    margin-top: 64px;
  }

  &:after {
    content: "";
    position: absolute;
    background: url(../../../assets/pattern3.svg) center bottom no-repeat;
    width: 100%;
    height: 115px;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 0.6;
  }
}

.order-extra-details,
.review-order {
  backface-visibility: hidden;
}
.order-extra-wrapper {
  perspective: 900px;
}
.order-extra-inner {
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transition: 0.4s all ease-out;
}
.order-extra-wrapper.reviewing {
  .order-extra-inner {
    transform: rotateY(-180deg);
    .order-extra-details {
    }
    .review-order {
    }
  }
}
