@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.order-actions-container {
  user-select: none;
  @media only screen and (max-width: 767px) {
    display: none;
  }
  // background: linear-gradient(180deg, rgba(245, 247, 249, 0) 60%, rgba(54, 56, 82, 0.24) 100%);
  background: linear-gradient(180deg, rgba($dark,0) 0%, rgba($dark,0.2) 100%);
  height: 145px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  color: $lighter;
  align-items: center;
  .order-actions {
    box-shadow: $z4;
    background: $dark;
    padding: 12px;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .button {
      margin: 0;
      padding-right: 24px;
      padding-left: 24px;
    }
    .summary {
      margin-left: 16px;
      padding: 0 12px;
      span {
        color: $placeholder;
        font-weight: 600;
      }
    }

    .action-send {
      border: 1px solid $brand;
      padding: 8px 24px;
      display: inline-block;
      border-radius: 5px;
      font-weight: 600;
      color: $white;
      background: $darker;
      user-select: none;
      cursor: pointer;
      img {
        margin-left: 8px;
      }
    }
  }
}
