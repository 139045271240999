@font-face {
  font-family: "Heebo";
  src: url("../assets/fonts/Heebo-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Heebo";
  src: url("../assets/fonts/Heebo-Light.ttf") format("opentype");
  font-weight: 300;
}
@font-face {
  font-family: "Heebo";
  src: url("../assets/fonts/Heebo-Medium.ttf") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Heebo";
  src: url("../assets/fonts/Heebo-Bold.ttf") format("opentype");
  font-weight: 700;
}
