@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";

.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $placeholder;
  padding: 12px 0;
  user-select: none;
  cursor: pointer;
  &:active {
    .product-increase {
      transform: scale(0.7);
    }
  }
  transition: 0.12s all;

  &:focus {
    // position: relative;
    // top: 1px;
    outline: none;
    box-shadow: $z5;
    border-radius: 5px;
    padding: 16px 16px;
    // color: $brand;

    .product-increase {
      // transition-delay: 0.12s !important;
      // box-shadow: 0 0 0 8px rgba($brand, 0.12);
      transform: scale(1.2);
      // box-shadow: 0 0 0 4px rgba($brand, 0.12) !important;
      // transform: scale(0.8);
    }
  }
  &:active {
    .product-increase {
      transform: scale(0.8);
      box-shadow: 0 0 0 0 rgba($brand, 0.4) !important;
    }
  }
  &:first-child {
    border-top: none;
  }
  .productDetails {
    // padding-right: 24px;
    flex: 1 1;
    .productName {
      font-weight: 600;
    }
    .productPrice {
      color: $light;
    }
  }
  .productId {
    color: $light;
    margin-left: 32px;
  }
  .productActions {
    line-height: 0;
    .product-amount {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      font-size: 14px;
      font-weight: bold;
      background: $brandDark;
      color: $white;
    }
    .product-increase,
    .product-decrease {
      box-shadow: 0 0 0 16px rgba($brand, 0);
      border-radius: 32px;
      transition: 0.32s all;
      cursor: pointer;
      &:active {
        box-shadow: 0 0 0 0px rgba($brand, 0.4);
        position: relative;
      }
    }
    .product-increase {
      // background: url("../../assets/icons/icon-plus-circle-filled.svg") center center no-repeat;
      // border-radius: 24px;
      box-shadow: 0 0 0 16px rgba($brand, 0);

      svg path {
        fill: $brand;
      }
      &:hover {
        // background-image: url("../../assets/icons/icon-plus-circle-filled.svg");
      }
      &:active {
        transform: scale(0.8);
      }
      &:focus {
        //   border: 4px solid $brand;
        outline: none;
        // box-shadow: 0 0 0 12px rgba($brand, 0.2);
      }
    }
    .product-decrease {
      background: url("../../assets/icons/icon-minus-circle.svg") center center no-repeat;
      &:hover {
        background-image: url("../../assets/icons/icon-minus-circle-filled.svg");
      }
    }
  }
}
