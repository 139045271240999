/*------------------------------------*\
    # Simple Typography Settings
\*------------------------------------*/

// Font Family
$font-family: "Heebo", serif;

// Base Font for HTML
$base-font-size: 14px;
$base-line-height: ($base-font-size * 1.6);

a {
  text-decoration: none;
}

// *,
html,
body {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  @media only screen and (max-width: 767px) {
    font-size: $base-font-size * 1.1;
    line-height: $base-line-height * 1.14;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 32px;

  @media only screen and (max-width: 767px) {
    font-size: 30px;
  }
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
