@import "../../styles/colors";
@import "../../styles/shadows";

.location-card {
  padding: 16px;
  height: 100%;

  .location-card-inner {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    box-shadow: $z3;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    top: 0;
    transition: 0.16s all;

    @media only screen and (max-width: 800px) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    &:hover {
      z-index: 2;
      top: -2px;
      box-shadow: $z6;
    }

    .location-image {
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      min-height: 180px;
      height: 100%;
      background-color: $placeholder;

      @media only screen and (max-width: 800px) {
        // height: 64px;
        width: 50%;
        max-width: 180px;
        // border-radius: 64px;
        // margin: 24px;
        // min-height: auto;
      }
    }

    .location-content {
      padding: 16px 0;

      @media only screen and (max-width: 767px) {
        // width: 80%;
      }

      .location-row {
        padding: 8px 24px;

        &.location-head {
          .location-name {
            margin: 0;
            color: $dark;
            overflow-wrap: break-word;
            font-weight: 600;
          }

          span {
            display: block;
            margin: 2px 0 0;
            font-size: 12px;
            color: $light;
          }
        }

        &.location-go {
          color: $brand;
          font-weight: 600;
        }

        &.location-statistics {
          display: flex;
          flex-direction: row;

          .location-column {
            line-height: 1.6;
            .column-key {
              font-size: 14px;
              color: $light;
            }
            .column-value {
              font-size: 14px;
              color: $dark;
            }
          }
          .location-column-separator {
            width: 1px;
            background: $placeholder;
            margin: 0 12px;
          }
        }
      }
    }
  }

  &.minified {
    padding: 0;

    > a {
      // cursor: default;
    }
    .location-card-inner {
      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      &:hover {
        z-index: 2;
        top: 0;
        box-shadow: $z3;
      }
      .location-image {
        height: 220px;
        @media only screen and (max-width: 991px) {
          height: 80px;
          width: 80px;
          border-radius: 80px;
          margin: 24px auto 0;
          min-height: auto;
        }
      }
      .location-content {
        .location-row {
          padding: 16px 32px;
          @media only screen and (max-width: 767px) {
            padding: 8px 32px;
          }
          &.location-head {
            @media only screen and (max-width: 991px) {
              text-align: center;
            }

            .location-name {
              font-size: 18px;
            }
            span {
              font-size: 14px;
              margin-top: 8px;
              @media only screen and (max-width: 800px) {
                display: none;
              }
            }
          }

          &.location-statistics {
            flex-direction: column;
            @media only screen and (max-width: 991px) {
              flex-direction: row;
              justify-content: center;
            }
            .location-column {
              padding: 8px 0;
              @media only screen and (max-width: 800px) {
                padding: 4px 0;
              }
              .column-key {
                font-size: 16px;
              }
              .column-value {
                font-size: 18px;
              }
            }
          }
          &.location-go {
            display: none;
          }
        }
      }
    }
  }
}
