@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";
header#main {
  //   background: #fff;
  //   border-bottom: 1px solid $placeholder;
  //   position: fixed;
  margin-bottom: 24px;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  color: $light;
  font-size: 20px !important;

  @media only screen and (max-width: 1300px) {
    margin-bottom: 0px;
  }

  .header {
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    @media only screen and (max-width: 1300px) {
      padding: 24px 0;
    }

    .logo {
      position: relative;
      top: 1px;
    }
    .search {
      width: 60%;
      input[type="text"] {
        width: 100%;
        background: $placeholder;
        &:focus {
          background: $white;
          border-color: $placeholder;
          box-shadow: $z2;
        }
        &::placeholder {
          color: $lighter;
        }
      }
      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
    .personal {
      display: flex;
      align-items: center;
      line-height: 1;
      .date {
        margin-left: 24px;
      }
    }
  }
}
.logout {
  font-size: 12px;
  font-weight: normal;
  display: block;
  a {
    color: $light;
    &:hover{
      color: $dark;
    }
  }
}
.user {
  display: flex;
  align-items: center;
  font-weight: 600;
  user-select: none;
  color: $dark;

  &:after {
    // background: url(../../assets/icons/icon-triangle-down.svg) center center no-repeat;
    // width: 12px;
    // height: 12px;
    // content: "";
    // margin-right: 4px;
  }

  .avatar {
    width: 40px;
    line-height: 1;
    margin-left: 12px;
    height: 40px;
    background: $borders;
    line-height: 40px;
    font-weight: 600;
    font-size: 14px;
    color: $lighter;
    text-align: center;
    border-radius: 40px;
    display: inline-block;
  }
}
