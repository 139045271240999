// @import url("https://fonts.googleapis.com/css?family=Heebo&display=swap");

// @import "./base";
@import "./fonts";
@import "./type";
@import "./colors";
@import "./shadows";
@import "./status";
@import "./input";
@import "./buttons";
@import "./tables";
@import "./mobile";
@import "../../node_modules/normalize.css/normalize.css";

* {
  box-sizing: border-box;
  touch-action: manipulation;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-overflow-scrolling: touch;
  background: $background;
  // font-size: 16px;
  color: $dark;
}

img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

.grad {
  background: linear-gradient(180deg, #f7fafc 20%, hsla(208, 37%, 94%, 1) 100%);
  width: 100%;
  height: 200px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  pointer-events: none;
}

.gradCover {
  background: linear-gradient(180deg, rgba($background, 0) 70%, rgba($background, 1) 100%);
  width: 100%;
  height: 200px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  pointer-events: none;
}

.App {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 48px 120px;

  @media only screen and (max-width: 1300px) {
    padding: 0 32px 32px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 16px 48px;
  }
}

iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
time {
  font-size: inherit;
}
::selection {
  color: $brand;
  background: lighten($brand, 50%);
}
.pageOrientation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1800px) {
  }
  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }
  @media only screen and (max-width: 800px) {
  }
  @media only screen and (max-width: 600px) {
  }

  .pageTitle {
    .amount {
      font-size: 18px;
      font-weight: 400;
      color: $light;

      &:before {
        content: " - ";
      }
    }

    .breadcrumbs {
      display: flex;
      margin: 0;

      li {
        color: $lighter;
        padding: 2px;

        a {
          color: $light;
        }

        &:not(:last-child):after {
          content: " \/\ ";
        }
      }
    }

    .title {
      margin-top: 8px;
      margin-bottom: 16px;
      color: $dark;
    }

    p {
      font-size: 16px;
      color: $light;
    }
  }
  .pageActions {
  }
}

html {
  direction: rtl;
  text-align: right;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// .row {
//   margin-right: 0px !important;
//   margin-left: 0px !important;
// }

.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12 {
  padding-left: 24px;
  padding-right: 24px;
  @media only screen and (max-width: 800px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.align-left {
  text-align: left;
}
a {
  color: $brand;
}

span,
strong {
  font: inherit;
}
strong {
  font-weight: 600;
}

.filter-option {
  flex: 1 1 auto;
  a {
    margin: 0;
    text-align: center;
    padding: 16px 24px;
    display: block;
    border-bottom: 3px solid transparent;
    user-select: none;
    color: $dark;
    transition: 0.08s all;
    div {
      display: flex;
      justify-items: center;
      align-items: center;
      align-self: center;

      svg {
        margin-left: 8px;
        path {
          fill: $light;
        }
      }
    }
  }

  &.active {
    a {
      color: $brand;
      font-weight: 600;
      border-bottom-color: $brand;

      svg path {
        fill: $brand;
      }
    }
  }
}

ul.check-list {
  li {
    background: url(../assets/icons/icon-check.svg) right 5px no-repeat;
    padding: 8px 0;
    padding-right: calc(24px + 8px);
    font-size: 16px;
  }
}
