@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";
.close {
  position: fixed;
  top: 24px;
  left: 40px;
  padding: 16px 32px;
  z-index: 2;
  border-radius: 32px;
  line-height: 1;
  background: $dark;
  color: $placeholder;
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.pattern {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  // background: url(../../assets/background.svg) center bottom no-repeat;
}
#newOrder {
  // &.isSending {
  //   &:after {
  //     content: "";
  //     position: fixed;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     z-index: 9;
  //     background: rgba($dark, 0.25);
  //   }
  // }
  .title {
    color: $brand;
  }
  .products-container {
    background: #fff;
    padding: 24px;
    border-radius: 10px;
    box-shadow: $z6;
    animation: rotate 0.5s;
    position: relative;
    // transform-origin: top center;
    @media only screen and (max-width: 767px) {
      display: none;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 40px;
    }

    @keyframes rotate {
      from {
        opacity: 0;
        // transform: perspective(1000px) rotateX(-45deg);
        left: 20px;
      }
      to {
        opacity: 1;
        // transform: perspective(1000px) rotateX(0);
        left: 0;
      }
    }
    .products-list-container {
      // max-height: 324px;
      //   margin-bottom: -16px;
      //   overflow-y: auto;
      // &::-webkit-scrollbar {
      //   width: 8px;
      // }
      // &::-webkit-scrollbar-thumb {
      //   background: rgba($lighter,0.6);
      //   border-radius: 8px;
      // }
      // &::-webkit-scrollbar-track {
      //   background: $white;
      //   border-radius: 8px;
      // }
    }
    .products-search {
      input {
        width: 100%;
      }
    }
    .products-list-title {
      h6 {
        margin: 32px 0 4px;
        color: $light;
        font-weight: 600;
      }
    }
  }
  .empty-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 120px;
    .closeSummary {
      font-weight: 600;
      color: $brand;
    }

    @media only screen and (max-width: 767px) {
      // display: none;
      text-align: center;
    }
    .cart {
      margin-bottom: 16px;
    }
    h1 {
      margin: 0 0 16px;
    }
    h4 {
      margin: 0 0 24px;
      color: $light;
    }
    .cancelOrder {
      border: 1px solid $borders;
      display: inline-block;
      padding: 10px 24px;
      border-radius: 5px;
      color: $brand;
      cursor: pointer;
      user-select: none;
      transition: 0.16s all ease-out;

      &:hover {
        border-color: darken($brand, 6%);
        background-color: darken($brand, 6%);
        color: $placeholder;
      }
      &:active {
        background-color: darken($brand, 12%);
        position: relative;
        top: 1px;
      }
    }
  }
}

.mobile-order-summary {
  display: none;

  &.shown {
    display: block;
  }

  .mobile-order-summary-inner {
    background: $background;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    overflow-y: auto;

    .summary-title {
      padding: 32px 24px;
      h1 {
        margin: 0 0 12px;
      }
      p {
        margin: 0;
        color: $light;
      }
    }

    .summary-controls {
      // background: $white;
      // position: fixed;
      // top: 0;
      // right: 0;
      // left: 0;
      // width: 100%;
      // z-index: 2;
      // box-shadow: $z3;
      padding: 20px;
      display: flex;
      justify-content: space-between;

      .back-to-order {
        // margin: 24px 0;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        color: $dark;
      
        .icon{
          margin-left: 4px;
        }
      }

      .send-order {
        color: $brand;
        padding: 4px 4px;
        font-weight: 600;
        font-size: 16px;
      }
    }
    &:after {
      // content: "";
      // position: fixed;
      // right: 0;
      // left: 0;
      // bottom: 0;
      // z-index: 1;
      // height: 60px;
      // height: 100px;
      // background: linear-gradient(180deg, rgba($background, 0) 0%, $background 100%);
    }
  }
  .items-wrapper {
    padding: 0 24px 24px;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $placeholder;
      padding: 14px 0;
      &:last-child{
        border-bottom-color: transparent;
      }
      .item-right {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .product-reset {
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon svg path {
          fill: $light;
        }
      }
      .productname {
        font-weight: 600;
        line-height: 1.6;
        .category {
          font-weight: 400;
          font-size: 12px;
          color: $light;
        }
      }

      .amount {
      }
    }
  }

  
}

.user-order-container {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
