@import "src/styles/colors.scss";
@import "src/styles/shadows.scss";
.login-container {
  width: 100%;
  height: calc(100vh - 120px);
  display: flex;
  align-items: center;
  justify-content: center;

  .App-logo{
    margin: 16px 0 32px;
  }

  @media only screen and (max-width: 767px) {
    width: auto;
    min-height: auto;
    display: block;
    margin: -16px;
  }

  .login-wrapper {
    margin-bottom: 10vh;
    background: $white;
    border-radius: 10px;
    width: 440px;
    box-shadow: $z6;
    padding: 40px;
    position: relative;

    @media only screen and (max-width: 767px) {
      width: auto;
      border-radius: 0;
      padding: 48px 24px;
      &:after {
        display: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      background: url(../../assets/pattern.svg) center center no-repeat;
      width: 720px;
      height: 300px;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      animation: 1s 0.3s backgroundSize forwards cubic-bezier(0.215, 0.61, 0.355, 1);
      background-size: 0%;

      @keyframes backgroundSize {
        from {
          background-size: 50%;
        }
        to {
          background-size: 100%;
        }
      }
    }
    .error {
      color: $red;
      margin-bottom: 40px;
    }
    .forgotpassword {
      margin-top: 24px;
      color: $light;
    }

    .login-content {
      // margin: -40px auto 0;
      fieldset {
        border: none;
        padding: 0;
        margin: 0;
        &:disabled label {
          top: -12px;
          font-size: 12px;
          color: $light;
        }
      }
      h1 {
        margin-top: 16px;
        margin-bottom: 48px;
      }
      .group {
        margin-bottom: 24px;
        position: relative;

        label {
          user-select: none;
          position: absolute;
          color: $light;
          font-size: 16px;
          top: 12px;
          right: 0px;
          transition: 0.2s ease all;
          // pointer-events: none;
          cursor: text;
          z-index: 1;
        }

        input[type="text"],
        input[type="password"] {
          background: $white;
          border: none;
          padding: 16px 8px 0;
          border-radius: 0;
          border: 1px solid transparent;
          line-height: 1;
          transition: 0.17s all ease-out;
          border-bottom: 1px solid $borders;
          outline: none;
          color: $dark;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus {
            border: 1px solid #fff;
            border-bottom: 1px solid $borders;
            // -webkit-text-fill-color: #fff;
            -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            transition: background-color 5000s ease-in-out 0s;
          }

          &:focus ~ label,
          &:valid ~ label {
            top: -6px;
            font-size: 12px;
            color: $light;
          }
          &:focus {
            border-bottom-color: $brand;
            & ~ label {
              color: $brand;
            }
          }
        }
      }

      button {
        margin-top: 48px;
      }
    }
  }
}

// .form-login {
//   flex: 1 1;
//   height: 100vh;
//   form {
//     display: flex;
//     height: 100%;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 100%;

//     .wrapper {
//       border: 1px solid #f0f0f0;
//       padding: 80px;

//       * {
//         margin: 4px;
//       }
//       input {
//         display: block;
//       }
//       label {
//         user-select: none;
//       }
//     }
//   }
// }
