.table {
  border-collapse: collapse;
  line-height: 1.5;
  width: 100%;
  font-size: 14px;
  &.table-editing {
    tr:hover td {
      background: $background;
    }
  }

  thead {
    color: $light;
    font-weight: 400;
    tr {
      border-top: 1px solid $placeholder;
      th {
        padding: 8px;
        font-weight: 400;
      }
    }
  }
  tbody {
    color: $dark;
    tr {
      border-top: 1px solid $placeholder;
      td {
        padding: 12px 8px;
        font-weight: 400;
      }
    }
  }
  tfoot {
    color: $dark;
    tr {
      border-top: 1px solid transparent;
      &:first-child {
        border-color: $placeholder;
        td {
          padding-top: 24px;
        }
      }
      &:last-child {
        td {
          padding-bottom: 16px;
        }
      }
      td {
        padding: 4px 32px;
        font-weight: 400;
        &.big {
          font-size: 18px;
          color: $brand;
          strong {
            border-bottom: 2px solid $brand;
          }
        }
      }
    }
  }
}

table .amount {
  user-select: none;
}

table .ordered-amount {
  // background: $placeholder;
  border-radius: 5px;
  padding: 1px 8px;
  color: $light;
  margin: 0 22px;
  display: inline-block;
  // text-decoration: line-through;
  position: relative;
  &:after {
    content: "";
    z-index: 2;
    height: 1px;
    // width: 100%;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 50%;
    background: rgba($brand, 0.3);
  }
}

table .productname .category {
  font-size: 12px;
  color: $light;
}

.productRemove {
  .icon {
    margin: 0;
    cursor: pointer;
    svg path{
      fill: $light;
    }
  }
}


.amount {
  input.amountText {
    max-width: 44px;
    padding: 4px 6px;
    margin: 0 4px;
    text-align: right;
    direction: rtl;
    background: $white;
    border: 1px solid $lighter;
    &:focus {
      background: $white;
      border-color: $brand;
    }
  }
  .status.status-pending {
    font-size: 14px;
  }
  .changeAmount {
    display: flex;
    align-items: center;
  }
  span {
    margin: 0 4px;
  }
  .product-increase,
  .product-decrease {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .product-decrease {
    background: url(../assets/icons/icon-minus-circle.svg) center center no-repeat;
  }
  .product-increase {
    background: url(../assets/icons/icon-plus-circle.svg) center center no-repeat;
  }
}
